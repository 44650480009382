html,
body {
  width: 100%;
  height: 100%;
  background-color: #111;
  padding: 0;
  margin: 0;
  font-family: "muli", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  user-select: none;
}

#root {
  width: 100%;
  height: 100%;
  background-color: #111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "muli";
  src: url("./fonts/muli/Muli-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "muli";
  font-style: italic;
  src: url("./fonts/muli/Muli-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "primitive";
  src: url("./fonts/primitive//Primitive-Regular.ttf") format("truetype");
}
